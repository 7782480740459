<template>
    <div class="pos-container">
        <div class="tabs">
            <v-slide-x-transition>
                <v-btn 
                color="success"
                v-if="editMode"
                @click="showAddTabDialog"
                >เพิ่ม</v-btn>
            </v-slide-x-transition>

            <v-list>
                <v-list-item-group
                    v-model="selectedTab"
                    color="primary"
                >
                    <draggable 
                    v-model="tabs" 
                    group="tabs" 
                    @start="drag=true" 
                    @end="drag=false"
                    handle=".handle"
                    >
                        <POSTabItem
                        v-for="(tab, index) in tabs" 
                        :key="index"
                        :name="tab.name"
                        :editMode="editMode"
                        @close="removeTab(tab.id)"
                        @showUpdateTabDialog="showUpdateTabDialog()"
                        ></POSTabItem>
                    </draggable>                    
                </v-list-item-group>
            </v-list>            

            <v-btn 
            :color="!editMode ? 'primary' : 'success'"
            class="mt-auto"
            @click="toggleEditMode"
            >{{ !editMode ? 'โหมดแก้ไข' : 'ปิดการแก้ไข'}}</v-btn>
        </div>

        <v-divider vertical></v-divider>

        <div class="product-container">
            <div class="search-container">
                <product-scanner
                :url="'/pos/' + deviceId + '/searchProduct'"
                :deviceId="deviceId"
                @addItem="addItem"
                @data="processSearchData"
                v-model="searchInput"
                ></product-scanner>
            </div>

            <v-divider></v-divider>

            <div class="product" style="position: relative;">
                <div 
                  v-if="selectedTab != undefined" 
                  class="grid-container"
                >
                  <div class="grid-item"
                  v-for="i in 16"
                  :key="productIndex(i)"
                  >
                    <EmptyCard 
                    v-if="!currentPageProducts[productIndex(i)]"
                    @click="showAddProductDialog(productIndex(i))"
                    @drop.prevent="dropProduct"
                    ></EmptyCard>                            
                    <ProductCard
                    v-else
                    :editMode="editMode"
                    :name="currentPageProducts[productIndex(i)].name"
                    :defaultPrice="currentPageProducts[productIndex(i)].defaultPrice"
                    :discountedPrice="currentPageProducts[productIndex(i)].discountedPrice"
                    @close="removeProduct(productIndex(i))"
                    :imageURL="!!currentPageProducts[productIndex(i)].images 
                    && currentPageProducts[productIndex(i)].images.length > 0 ?
                    currentPageProducts[productIndex(i)].images[0].URI : null"
                    :draggable="false"
                    @dragstart="setDragData($event, productIndex(i))"
                    @dragover.prevent
                    @drop.prevent="dropProduct($event, productIndex(i))"
                    @click="showAddOrderItemDialog(productIndex(i))"
                    ></ProductCard>
                  </div>
                </div>                
                <!--
                <v-container v-if="selectedTab != undefined">
                    <v-row 
                    v-for="i in config.maxRow"
                    :key="i"
                    :style="{'max-height': (100 / config.maxRow) + '%', 'height': '100%'}"
                    >
                        <v-col 
                        cols="12" 
                        :sm="12 / config.maxColumn"
                        v-for="n in config.maxColumn"
                        :key="i + '_' + n"
                        style="max-height: 100%; height: 100%;"
                        >
                            <EmptyCard 
                            v-if="!currentPageProducts[productIndex(i, n)]"
                            @click="showAddProductDialog(productIndex(i, n))"
                            @drop.prevent="dropProduct"
                            ></EmptyCard>                            
                            <ProductCard
                            v-else
                            :editMode="editMode"
                            :name="currentPageProducts[productIndex(i, n)].name"
                            :defaultPrice="currentPageProducts[productIndex(i, n)].defaultPrice"
                            :discountedPrice="currentPageProducts[productIndex(i, n)].discountedPrice"
                            @close="removeProduct(productIndex(i, n))"
                            :imageURL="!!currentPageProducts[productIndex(i, n)].images 
                            && currentPageProducts[productIndex(i, n)].images.length > 0 ?
                            currentPageProducts[productIndex(i, n)].images[0].URI : null"
                            :draggable="false"
                            @dragstart="setDragData($event, productIndex(i, n))"
                            @dragover.prevent
                            @drop.prevent="dropProduct($event, productIndex(i, n))"
                            @click="showAddOrderItemDialog(productIndex(i, n))"
                            ></ProductCard>
                        </v-col>                       
                    </v-row>                    
                </v-container>                               
                -->
                
                <v-overlay
                :absolute="true"
                :opacity="0.5"
                :value="showSearchDataOverlay"
                >
                  <v-card                    
                    min-width="600"
                    min-height="600"
                    max-height="600"
                  >
                    <v-toolbar
                    color="info"
                    min-width="300"
                    >
                      <v-btn
                        icon
                        dark
                        @click="closeSearchDataOverlay"
                      >
                        <v-icon>{{ icons.mdiClose }}</v-icon>
                      </v-btn>
                      <v-toolbar-title>ผลการค้นหา</v-toolbar-title>
                    </v-toolbar>

                    <div style="overflow-y: auto;height: 500px;">
                      <v-card-text v-if="!!searchData && searchData.length > 0">
                        <div 
                          v-for="(productData, index) in searchData" 
                          :key="index"                      
                        >
                            <div class="d-flex" @click="showAddOrderItemDialogByProductData(productData)">
                              <div style="width: 100px; height: 100px;" class="d-flex justify-center align-center">
                                <product-image :image="!!productData.images && !!productData.images[0] ? productData.images[0].URI : null" :key="productData.id" />                              
                              </div>
                              <div class="pa-4 d-flex flex-column">
                                <div>{{ productData.name }}</div>                          
                                <div>{{ productData.barCode }}</div>
                                <div 
                                v-if="productData.stock && !!productData.inventory && !!productData.inventory[0]"
                                class="mt-auto"
                                >ขายได้ : {{ productData.inventory[0].quantity - productData.inventory[0].reserved }}</div>
                              </div>
                            </div>                                                
                            <v-divider></v-divider>
                        </div>                      
                      </v-card-text>
                      <h2 v-else class="text-center mt-6">ไม่พบสินค้าที่ตรงกับคำค้นหา</h2>
                    </div>                    
                  </v-card>
                </v-overlay>
            </div>

            <v-divider></v-divider>

            <div class="pagination">
                <v-pagination
                v-model="currentPage"
                :length="config.maxPage"
                ></v-pagination>
            </div>
        </div>

        <v-divider vertical></v-divider>

        <div class="order">
          <v-form 
          v-model="isOrderValid"
          style="display: flex; flex-direction: column; height: 100%;">
            <v-container class="h-full" style="overflow-y: scroll;" ref="container">
              <template
              v-for="(item, index) in order.orderItems"
              class="d-flex flex-column pa-2"
              >
                <v-row :key="'1_' + index">
                  <v-col class="d-flex">
                    <light-box v-if="!!item.imageURL"
                    :src="item.imageURL"
                    max-width="70"
                    max-height="70"
                    ></light-box>
                    <product-image v-else :image="null" />

                    <div class="d-flex flex-column ml-2">
                        <div class="black--text">{{ item.name }}</div>
                        <div class="mt-auto text-caption black--text">{{ item.sku }}</div>
                    </div>
                    <div>
                      <v-btn 
                      icon 
                      @click="deleteItem(index)"
                      >
                        <v-icon>{{ icons.mdiDeleteOutline }}</v-icon>
                      </v-btn>
                    </div>
                  </v-col>                    
                </v-row>  
                <v-row :key="'2_' + index" dense class="mt-2">
                  <v-col cols="6">
                    <v-text-field
                        class="input-align-right"                        
                        suffix="฿"
                        outlined
                        dense
                        v-model.number="item.price"
                        :rules="[validators.minValueValidator(item.price, 0)]"
                        label="ราคาต่อหน่วย"
                        hide-details                      
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <toogle-text-field
                      class="input-align-right my-auto"                      
                      outlined
                      dense
                      v-model.number="item.discount"
                      v-bind:toogleValue.sync="item.discount_type"
                      :rules="getDiscountRules(item.discount, item.discount_type)"
                      label="ส่วนลด/หน่วย"
                      hide-details
                    ></toogle-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      class="input-align-right my-auto"                      
                      outlined
                      dense
                      v-model.number="item.quantity"
                      :rules="[validators.minValueValidator(item.quantity, 1), validators.integerValidator]"   
                      label="จำนวน"
                      hide-details
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6" class="text-right pt-3">
                    {{ item.priceSum }} ฿                      
                  </v-col>
                </v-row>                
              </template>
              <v-row no-gutters ref="latest"></v-row>
            </v-container>

            <v-divider></v-divider>   

            <v-simple-table style="height: 300px;">
              <tbody>
                <tr class="black--text">
                  <td class="text-body-1" width="50%" style="border-bottom: none">จำนวนทั้งหมด</td>
                  <td class="text-body-1 text-right" style="border-bottom: none">{{ order.quantityCount }}</td>
                </tr>
                <tr class="black--text">
                  <td class="text-body-1" width="50%" style="border-bottom: none">ราคาก่อนส่วนลด</td>
                  <td class="text-body-1 text-right" style="border-bottom: none">{{ order.priceBeforeDiscount | formatNumber }}</td>
                </tr>
                <tr>
                  <td width="50%" style="border-bottom: none" class="text-body-1">ส่วนลดทั้งบิล</td>
                  <td style="border-bottom: none">
                    <toogle-text-field
                      class="input-align-right"                      
                      outlined
                      dense
                      v-model.number="order.discount"
                      v-bind:toogleValue.sync="order.discount_type"
                      :rules="getDiscountRules(order.discount, order.discount_type)"                          
                      hide-details
                    ></toogle-text-field>
                  </td>
                </tr>              
                <tr>
                  <td width="50%" style="border-bottom: none" class="text-body-1">รวมทั้งหมด</td>
                  <td class="text-right text-body-1" style="border-bottom: none">{{ order.totalPrice | formatNumber }}</td>
                </tr>
                <tr>
                  <td colspan="2">
                    <v-btn 
                    block 
                    color="primary" 
                    @click="showCheckOutDialog = true"
                    :disabled="!isOrderValid || order.orderItems.length == 0"
                    >ชำระเงิน</v-btn>
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-form>
        </div>

        <v-dialog
          v-model="tabDialog.isShow"
          persistent
          max-width="364"
        >
          <v-card>
            <v-toolbar
              color="primary"       
              dark
            >เพิ่ม / แก้ไข แทบ</v-toolbar>

            <v-form v-model="tabDialog.isFormValid" @submit.prevent="submitTabForm">
              <v-card-text class="mt-2">              
                <v-text-field         
                  label="ชื่อ"       
                  v-model="tabDialog.formData.name"
                  outlined                            
                  hide-details="auto"
                  dense
                ></v-text-field>            
              </v-card-text>    
            </v-form>        
            
            <v-card-actions>
              <v-btn
                color="secondary"
                outlined                
                :disabled="sendingData"
                @click="tabDialog.isShow = false"
              >
                ยกเลิก
              </v-btn>

              <v-btn
                color="primary"                               
                :disabled="!tabDialog.isFormValid"
                :loading="sendingData"
                @click="submitTabForm"                
              >
                {{ !tabDialog.id ? "สร้าง" : "แก้ไข"}}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog
          v-model="setProductDialog.isShow"          
          max-width="450"
        >
          <v-card>
            <delay-autocomplete
              v-model="searchText"
              outlined
              cache-items              
              placeholder="พิมพ์เพื่อค้นหาสินค้า"
              no-data-text="ไม่พบข้อมูล"
              :url="'/pos/' + this.deviceId + '/searchProduct'"
              dense
              :lazySearch="true"
              return-object
              ref="productAutocomplete"
              item-text="name"
              item-value="id"
              hide-selected
              :filter="filterItem"
              hide-details="auto"
              @change="setProduct"
            >
              <template v-slot:item="{ item, parent }">
                <div style="width: 100px; height: 100px;" class="d-flex justify-center align-center">
                  <product-image :image="!!item.images && !!item.images[0] ? item.images[0].URI : null" :key="item.id" />                              
                </div>
                <div class="ml-4">
                  <div>{{ item.name }}</div>                  
                </div>                            
              </template>
            </delay-autocomplete>
          </v-card>
        </v-dialog>

        <v-dialog
          v-model="addOrderItemDialog.isShow"          
          max-width="450"
          persistent
        >
          <v-card>
            <v-img
              max-height="250"
              :src="!!addOrderItemDialog.product && !!addOrderItemDialog.product.images && addOrderItemDialog.product.images.length > 0 ? addOrderItemDialog.product.images[0].URI : null"
            ></v-img>

            <v-card-title>{{ !!addOrderItemDialog.product ? addOrderItemDialog.product.name : '' }}</v-card-title>

            <v-form class="px-8">
              <v-row>
                <v-col>
                  <v-text-field       
                    v-model.number="addOrderItemDialog.quantity"                       
                    :append-outer-icon="icons.mdiPlus"
                    :prepend-icon="icons.mdiMinus"
                    outlined                
                    label="จำนวน"                                
                    @click:append-outer="plusClick"
                    @click:prepend="minusClick"
                    type="number"
                    class="input-align-right"
                  ></v-text-field>
                </v-col>
              </v-row>              
            </v-form>            

            <v-card-actions>
              <v-btn
                color="secondary"               
                outlined                 
                large              
                @click="addOrderItemDialog.isShow = false"
              >
                ยกเลิก
              </v-btn>

              <v-btn
                color="primary"               
                large                          
                @click="addOrderItem"    
                class="ml-auto"            
              >
                ตกลง
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog
          persistent
          v-model="showCheckOutDialog"
          max-width="600"
        >
          <check-out 
          :value="order"
          :payments="payments"
          :deviceId="deviceId"
          @close="showCheckOutDialog = false"
          @done="doneOrder"
          ></check-out>
        </v-dialog>

        <v-dialog 
        max-width="400"
        v-model="changeDialog.isShow"
        >
          <v-card class="py-10">
            <h1 class="text-center">ทอน : {{ changeDialog.change }} ฿</h1>            
          </v-card>
        </v-dialog>
    </div>
</template>
<script>
import { OrderItem } from '@/classes/order/OrderItem'
import { POSOrder } from '@/classes/pos/POSOrder'
import ToogleTextField from '@/components/ToogleTextField.vue'
import { asyncDelete, asyncGet, asyncPost, asyncUpdate, asyncUpdateAsJson } from '@/helpers/asyncAxios'
import errorParser from '@/plugins/errorparser'
import { integerValidator, maxValueValidator, minValueValidator, required } from '@core/utils/validation'
import { mdiBarcodeScan, mdiClose, mdiDeleteOutline, mdiMinus, mdiPlus } from '@mdi/js'
import draggable from 'vuedraggable'
import CheckOut from './CheckOut.vue'
import EmptyCard from './EmptyCard.vue'
import POSTabItem from './POSTabItem.vue'
import ProductCard from './ProductCard.vue'
import ProductScanner from './ProductScanner.vue'
export default {
  props: ['deviceId'],
  data() {
    return {
      searchInput: '',
      searchData: [],
      selectedTab: null,
      editMode: false,
      tabs: [],
      payments: [],
      sendingData: false,
      config: {
        maxPage: 10,
        maxRow: 4,
        maxColumn: 4,
      },
      currentPage: 1,
      currentPageProducts: {},
      searchText: null,
      tabDialog: {
        isShow: false,
        isFormValid: false,
        id: null,
        formData: {
          name: '',
          index: 0,
        },
      },
      drag: false,
      setProductDialog: {
        isShow: false,
        position: 0,
      },
      addOrderItemDialog: {
        isShow: false,
        product: null,
        quantity: 0,
      },
      order: new POSOrder({}),
      isOrderValid: false,
      validators: {
        maxValueValidator,
        minValueValidator,
        required,
        integerValidator,
      },
      showCheckOutDialog: false,
      icons: {
        mdiDeleteOutline,
        mdiPlus,
        mdiMinus,
        mdiBarcodeScan,
        mdiClose,
      },
      changeDialog: {
        isShow: false,
        change: 0,
      },
      showSearchDataOverlay: false,
    }
  },
  watch: {
    drag(value) {
      this.selectedTab = null

      if (value) return

      this.updateBatch()
    },
    async selectedTab(value) {
      if (value === null || value === undefined) return (this.currentPageProducts = {})

      const { paginations } = this.tabs[value]

      this.setCurrentPagination(paginations)
    },
    searchData(value) {
      if (!!value) this.showSearchDataOverlay = true
      else this.showSearchDataOverlay = false
    },
  },
  async created() {
    this.$store.commit('app/SET_GLOBAL_LOADING', true)

    try {
      const { payments, tabs } = await asyncGet('/pos/' + this.deviceId + '/initPOS')

      this.tabs = tabs
      this.payments = payments
    } catch (error) {
      this.$root.showCommonDialog('มีปัญหา', errorParser(error))
    }

    this.$store.commit('app/SET_GLOBAL_LOADING', false)
  },
  methods: {
    showAddOrderItemDialog(productIndex) {
      const product = this.currentPageProducts[productIndex]

      this.addOrderItemDialog.isShow = true
      this.addOrderItemDialog.product = product
      this.addOrderItemDialog.quantity = 1
    },
    showAddOrderItemDialogByProductData(product) {
      this.addOrderItemDialog.isShow = true
      this.addOrderItemDialog.product = product
      this.addOrderItemDialog.quantity = 1
    },
    plusClick() {
      this.addOrderItemDialog.quantity++
    },
    minusClick() {
      this.addOrderItemDialog.quantity--

      if (this.addOrderItemDialog.quantity < 0) this.addOrderItemDialog.quantity = 0
    },
    addOrderItem() {
      const { id, name, sku, barCode, is_vat, unit, images, defaultPrice, discountedPrice } =
        this.addOrderItemDialog.product

      const imageURL = !!images && images.length > 0 ? images[0].URI : null

      const orderItem = new OrderItem(
        id,
        imageURL,
        name,
        sku,
        barCode,
        '',
        this.addOrderItemDialog.quantity,
        defaultPrice,
        !!discountedPrice ? defaultPrice - discountedPrice : 0,
        'value',
        !!is_vat ? 7 : 0,
        unit,
      )
      this.order.addOrderItem(orderItem)

      this.addOrderItemDialog.isShow = false
    },
    deleteItem(index) {
      const order = this.order
      const newOrderItems = [...order.orderItems]
      newOrderItems.splice(index, 1)
      order.orderItems = newOrderItems
    },
    setDragData(event, productIndex) {
      event.dataTransfer.setData('product', productIndex)
    },
    dropProduct(event, targetProductIndex) {
      const sourceProductIndex = event.dataTransfer.getData('product')

      const sourceProduct = this.currentPageProducts[sourceProductIndex]
      const targetProduct = this.currentPageProducts[targetProductIndex]

      //TODO : switch Product
    },
    setCurrentPagination(paginationData) {
      this.currentPageProducts = {}

      paginationData.forEach(element => {
        this.currentPageProducts[element.position] = element.product
      })
    },
    async setProduct(product) {
      if (!product) return

      try {
        await asyncPost(
          '/pos/' +
            this.deviceId +
            '/tab/' +
            this.tabs[this.selectedTab].id +
            '/product/' +
            this.setProductDialog.position,
          {
            product_id: product.id,
          },
        )

        this.currentPageProducts[this.setProductDialog.position] = product

        this.getPagination(this.tabs[this.selectedTab].id)
      } catch (error) {
        this.$root.showCommonDialog('มีปัญหา', errorParser(error))
      }

      this.setProductDialog.isShow = false
    },
    async removeProduct(position) {
      try {
        const response = await asyncDelete(
          '/pos/' + this.deviceId + '/tab/' + this.tabs[this.selectedTab].id + '/product/' + position,
        )

        this.tabs[this.selectedTab].paginations = response

        this.setCurrentPagination(response)
      } catch (error) {
        this.$root.showCommonDialog('มีปัญหา', errorParser(error))
      }
    },
    async getPagination($tab_id) {
      try {
        const response = await asyncGet('/pos/' + this.deviceId + '/tab/' + $tab_id)

        const tab = this.tabs.find(item => item.id == $tab_id)

        tab.paginations = response
      } catch (error) {
        this.$root.showCommonDialog('มีปัญหา', errorParser(error))
      }
    },
    /*
    productIndex(i, n) {
      const rowIndex = i - 1
      const columnIndex = n - 1

      const pageIndex = rowIndex * this.config.maxColumn + columnIndex

      const perPage = this.config.maxColumn * this.config.maxRow

      return (this.currentPage - 1) * perPage + pageIndex
    },
    */
    productIndex(i) {
      return (this.currentPage - 1) * 16 + i
    },
    showAddTabDialog() {
      this.tabDialog.isShow = true
      this.tabDialog.id = null
      this.tabDialog.formData = {
        name: '',
        index: this.tabs.length,
      }
    },
    showAddProductDialog(position) {
      this.setProductDialog.isShow = true
      this.setProductDialog.position = position
      this.searchText = null
    },
    showUpdateTabDialog() {},
    async removeTab(id) {
      const index = this.tabs.findIndex(item => item.id == id)

      this.tabs.splice(index, 1)

      try {
        await asyncDelete('/pos/' + this.deviceId + '/tab/' + id)
      } catch (error) {
        this.$root.showCommonDialog('มีปัญหา', errorParser(error))
      }
    },
    toggleEditMode() {
      this.editMode = !this.editMode
    },
    async updateBatch() {
      const tabs = this.tabs.map((item, index) => {
        return {
          id: item.id,
          name: item.name,
          index: index,
        }
      })

      try {
        await asyncUpdateAsJson('/pos/' + this.deviceId + '/tab/batch', { tabs: tabs })
      } catch (error) {
        this.$root.showCommonDialog('มีปัญหา', errorParser(error))
      }
    },
    async submitTabForm() {
      this.sendingData = true

      try {
        if (!!this.tabDialog.formData.id) {
          await asyncUpdate('/pos/' + this.deviceId + '/tab/' + this.tabDialog.formData.id, this.tabDialog.formData)
        } else {
          const response = await asyncPost('/pos/' + this.deviceId + '/tab', this.tabDialog.formData)

          this.tabs.push(response)
        }
      } catch (error) {
        this.$root.showCommonDialog('มีปัญหา', errorParser(error))
      }

      this.sendingData = false
      this.tabDialog.isShow = false
    },
    filterItem(item, queryText, itemText) {
      const tokens = queryText.split(' ')

      const searchText = tokens == queryText ? queryText : tokens[0]

      return (
        itemText.toLocaleLowerCase().indexOf(searchText.toLocaleLowerCase()) > -1 ||
        (item.sku && item.sku.toLocaleLowerCase().indexOf(searchText.toLocaleLowerCase()) > -1) ||
        (item.barCode && item.barCode.toLocaleLowerCase().indexOf(searchText.toLocaleLowerCase()) > -1)
      )
    },
    getDiscountRules(discount_value, discount_type) {
      return discount_type == 'percent'
        ? [minValueValidator(discount_value, 0), maxValueValidator(discount_value, 100)]
        : [minValueValidator(discount_value, 0)]
    },
    doneOrder(change) {
      this.showCheckOutDialog = false
      this.order = new POSOrder({})

      if (change > 0) {
        this.changeDialog.isShow = true
        this.changeDialog.change = change
      }

      this.$emit('done')
    },
    addItem({ id, name, images, sku, barCode, defaultPrice, discountedPrice, vat, available, unit }) {
      const order = this.order
      const currentItems = order.orderItems

      const foudItem = currentItems.find(data => data.id == id)

      if (!foudItem) {
        order.addOrderItem(
          new OrderItem(
            id,
            images && images.length > 0 ? images[0].URI : null,
            name,
            sku,
            barCode,
            null,
            1,
            defaultPrice,
            !!discountedPrice ? defaultPrice - discountedPrice : 0,
            !!discountedPrice ? 'value' : 'percent',
            !!vat ? 7 : null,
            unit,
          ),
        )
      } else {
        foudItem.quantity++
      }

      this.$notify({
        group: 'scannedResult',
        title: 'ผลการสแกน ' + barCode,
        type: 'info',
        text: 'เพิ่ม ' + name + ' ในบิลสำเร็จ',
      })
    },
    processSearchData(event) {
      this.searchData = event
    },
    closeSearchDataOverlay() {
      this.searchInput = ''
      this.showSearchDataOverlay = false
    },
  },
  components: {
    ProductCard,
    EmptyCard,
    POSTabItem,
    draggable,
    ProductCard,
    ToogleTextField,
    CheckOut,
    ProductScanner,
  },
}
</script>
<style scoped>
.pos-container {
  display: flex;
  min-height: calc(100vh - 64px);
  max-height: calc(100vh - 64px);
}

.pos-container > .tabs {
  display: flex;
  flex-direction: column;
  width: 20%;
}

.pos-container > .product-container {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.pos-container > .product-container > .search-container {
  padding: 6px 6px;
}

.pos-container > .product-container > .product {
  background-color: rgba(247, 240, 255, 0.68);
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.pos-container > .product-container > .pagination {
  min-height: 50px;
}

.pos-container > .order {
  width: 30%;
  min-width: 300px;
  /*
  display: flex;
  flex-direction: column;
  */
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(4, 1fr);
  gap: 10px;
  width: 100%; /* Responsive size */
  max-height: 85vh;
  padding: 10px;
}

.grid-item {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

@media (max-width: 600px) {
  .grid-container {
    width: 90vmin;
    height: 90vmin;
  }

  .grid-item {
    font-size: 1.5em;
  }
}
</style>