<template>
    <div
    v-bind="$attrs"
    v-on="$listeners"
    class="empty-card"
    >
        <v-icon size="64" color="primary">{{ icons.mdiPlus }}</v-icon>
    </div>    
</template>
<script>
import { mdiPlus } from '@mdi/js'
export default {
  data() {
    return {
      icons: {
        mdiPlus,
      },
    }
  },
}
</script>
<style scoped>
.empty-card {
  display: flex;
  width: 100%;
  border: dashed 1px red;
  border-radius: 4px;
  height: 100%;
  justify-content: center;
  align-items: center;
  max-width: 300px;
  margin: auto;
}

.empty-card:hover {
  cursor: pointer;
}
</style>