<template>
    <div>
      <v-text-field
          outlined                    
          placeholder="ค้นหา บาร์โคด/ชื่อสินค้า"
          dense
          hide-details="auto"
          :prepend-inner-icon="icons.mdiBarcodeScan"
          @keydown.enter="scanProductByBarCode"          
          :loading="isLoading"
          v-bind="$attrs"
          v-on="$listeners"
      ></v-text-field>
    </div>    
</template>
<script>
import { asyncGet } from '@/helpers/asyncAxios'
import { mdiBarcodeScan } from '@mdi/js'
export default {
  name: 'DelayAutocomplete',
  props: {
    url: {
      type: String,
      required: true,
    },
    deviceId: {
      required: true,
    },
  },
  data() {
    return {
      isLoading: false,
      scannedCache: [],
      data: [],
      searchDelayTimer: null,
      firstSearch: false,
      abortController: null,
      icons: {
        mdiBarcodeScan,
      },
    }
  },
  watch: {
    '$attrs.value': function (value) {
      if (this.searchDelayTimer) clearTimeout(this.searchDelayTimer)

      if (!value) {
        this.data = null
        this.$emit('data', this.data)
        return
      }

      this.searchDelayTimer = setTimeout(this.search, 750, value)
    },
  },
  methods: {
    async search(searchInput) {
      const root = this.$root

      if (this.abortController) {
        this.abortController.abort()
      }

      this.abortController = new AbortController()

      this.isLoading = true
      try {
        const response = await asyncGet(
          this.url,
          { search: searchInput },
          {
            signal: this.abortController.signal,
          },
        )
        this.data = response
      } catch (error) {
        root.showCommonDialog('มีปัญหา', error)
      } finally {
        this.abortController = null
      }
      this.isLoading = false

      this.$emit('data', this.data)
    },
    async scanProductByBarCode(event) {
      if (this.isLoading) return

      if (this.searchDelayTimer) clearTimeout(this.searchDelayTimer)

      this.isLoading = true
      const tmp = this.$attrs.value
      try {
        const searchData = this.scannedCache.find(data => data.barCode == this.$attrs.value)

        this.$emit('input', '')

        if (!searchData) {
          const response = await asyncGet('pos/' + this.deviceId + '/' + tmp + '/barcode', {})

          this.scannedCache.push(response)

          this.$emit('addItem', response)
        } else {
          this.$emit('addItem', searchData)
        }
      } catch (error) {
        this.$notify({
          group: 'scannedResult',
          title: 'ผลการสแกน ' + tmp,
          type: 'error',
          text: error,
        })
      }

      this.isLoading = false
    },
  },
}
</script>