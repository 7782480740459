<template>
    <v-list-item
    v-bind="$attrs"
    v-on="$listeners"
    >
        <v-fab-transition>
            <v-list-item-icon v-if="editMode" @click.stop="$emit('close')">
                <v-icon v-text="icons.mdiClose"></v-icon>
            </v-list-item-icon>
        </v-fab-transition>

        <v-list-item-content>            
            <v-list-item-title                 
                v-text="name"
            ></v-list-item-title>                  
        </v-list-item-content>    
        
        <v-fab-transition>
            <v-list-item-icon v-if="editMode" @click.stop="$emit('showUpdateTabDialog')">
                <v-icon v-text="icons.mdiPencil"></v-icon>
            </v-list-item-icon>
        </v-fab-transition>

        <v-fab-transition>
            <v-list-item-icon v-if="editMode">
                <v-icon v-text="icons.mdiCursorMove" class="handle"></v-icon>
            </v-list-item-icon>
        </v-fab-transition>
    </v-list-item>
</template>
<script>
import { mdiClose, mdiCursorMove, mdiPencil, mdiPencilOff } from '@mdi/js'
export default {
  props: {
    editMode: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
    },
  },
  data() {
    return {
      icons: {
        mdiClose,
        mdiCursorMove,
        mdiPencil,
        mdiPencilOff,
      },
      editName: false,
      newName: this.name,
    }
  },
  methods: {},
}
</script>