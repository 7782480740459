<template>
    <v-card
    v-bind="$attrs"
    v-on="$listeners"
    style="position: relative;"
    :class="['product-card', {'mark-delete': markDelete}]"    
    >
        <v-img
        :src="imageURL || placeholderImage"        
        max-height="100%"
        max-width="100%"
        contain
        class="align-end"
        >
          <v-card-title>{{ name }}</v-card-title>

          <div class="d-flex align-center px-5">
            <div>฿</div>
            
            <div class="text-h6 ml-2" v-if="!!discountedPrice">
                <strike>{{ defaultPrice }}</strike>
            </div>
            <div class="text-h6 ml-2" v-else>
                {{ defaultPrice }}
            </div>
        
            <div class="text-subtitle-1 ml-4">
              {{ discountedPrice }}
            </div>
          </div>          
        </v-img>        

        <v-fab-transition>
            <v-btn
            v-show="editMode"
            color="primary"            
            absolute
            top
            right
            fab
            small
            @click.stop="remove"
            class="mt-6"
            >
                <v-icon>{{icons.mdiMinus}}</v-icon>
            </v-btn>
        </v-fab-transition>
        
    </v-card>
</template>
<script>
import placeholderImage from '@/bulitInImages/noimage.svg'
import { mdiMinus } from '@mdi/js'
export default {
  props: {
    editMode: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      default: '',
    },
    defaultPrice: {
      type: [Number, String],
      default: null,
    },
    discountedPrice: {
      type: [Number, String],
      default: null,
    },
    imageURL: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      icons: {
        mdiMinus,
      },
      markDelete: false,
      placeholderImage: placeholderImage,
    }
  },
  methods: {
    remove() {
      this.markDelete = true
      this.$emit('close')
    },
  },
}
</script>
<style scoped>
.product-card {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  max-width: 300px;
  max-height: 300px;
  margin: auto;
}

.product-card.mark-delete {
  transform: scale(0);
  transition: all 1s;
}
</style>