<template>
    <div class="d-flex" ref="numpad">
      <div class="numpad-container">
        <div class="numpad-row">
          <div class="number-container">
            <v-btn 
            block
            @click="$emit('numClick', '1')"
            >1</v-btn>
          </div>
          <div class="number-container">
            <v-btn 
            block
            @click="$emit('numClick', '2')"
            >2</v-btn>
          </div>
          <div class="number-container">
            <v-btn 
            block
            @click="$emit('numClick', '3')"
            >3</v-btn>
          </div>
        </div>
        <div class="numpad-row">
          <div class="number-container">
            <v-btn 
            block
            @click="$emit('numClick', '4')"
            >4</v-btn>
          </div>
          <div class="number-container">
            <v-btn 
            block
            @click="$emit('numClick', '5')"
            >5</v-btn>
          </div>
          <div class="number-container">
            <v-btn 
            block
            @click="$emit('numClick', '6')"
            >6</v-btn>
          </div>
        </div>
        <div class="numpad-row">
          <div class="number-container">
            <v-btn 
            block
            @click="$emit('numClick', '7')"
            >7</v-btn>
          </div>
          <div class="number-container">
            <v-btn 
            block
            @click="$emit('numClick', '8')"
            >8</v-btn>
          </div>
          <div class="number-container">
            <v-btn 
            block
            @click="$emit('numClick', '9')"
            >9</v-btn>
          </div>
        </div>
        <div class="numpad-row">
          <div class="number-container">
            <v-btn 
            block
            @click="$emit('clear')"
            color="primary"
            >C</v-btn>
          </div>
          <div class="number-container">
            <v-btn 
            block
            @click="$emit('numClick', '.')"
            >.</v-btn>
          </div>
          <div class="number-container">
            <v-btn 
              block
              @click="$emit('delete')"
              color="black"
              class="white--text"
              >DEL</v-btn>
          </div>
        </div>        
      </div>
      <div class="zero-container">
        <div class="zero-row">
          <v-btn 
          block
          @click="$emit('numClick', '0')"
          color="info"
          >0</v-btn>
        </div>
        <div class="zero-row">
          <v-btn 
          block
          @click="$emit('numClick', '00')"
          color="info"
          >00</v-btn>
        </div>
        <div class="zero-row">
          <v-btn 
          block
          @click="$emit('numClick', '000')"
          color="info"
          >000</v-btn>
        </div>
        <div class="zero-row">
          <v-btn 
          block
          @click="$emit('numClick', '0000')"
          color="info"
          >0000</v-btn>
        </div>
      </div>      
    </div>
</template>
<script>
export default {
  data() {
    return {}
  },
  methods: {
    keydown(event) {
      /*
      if (event.key == 'Backspace') this.$emit('delete')
      else if (event.key == '1') this.$emit('numClick', '1')
      else if (event.key == '2') this.$emit('numClick', '2')
      else if (event.key == '3') this.$emit('numClick', '3')
      else if (event.key == '4') this.$emit('numClick', '4')
      else if (event.key == '5') this.$emit('numClick', '5')
      else if (event.key == '6') this.$emit('numClick', '6')
      else if (event.key == '7') this.$emit('numClick', '7')
      else if (event.key == '8') this.$emit('numClick', '8')
      else if (event.key == '9') this.$emit('numClick', '9')
      else if (event.key == '0') this.$emit('numClick', '0')
      else if (event.key == '.') this.$emit('numClick', '.')
    */
    },
  },
  created() {
    //window.onkeydown = this.keydown
    //window.addEventListener('keydown', this.testKeyDown)
  },
}
</script>
<style scoped>
.numpad-container {
  display: flex;
  flex-direction: column;
}

.numpad-container .numpad-row {
  display: flex;
}

.numpad-container .numpad-row .number-container {
  height: 75px;
  width: 100px;
}

.numpad-container .numpad-row .number-container button {
  height: 100% !important;
  border-radius: unset;
}

.zero-container {
  width: 100%;
}

.zero-container .zero-row {
  height: 75px;
}

.zero-container > .zero-row > button {
  height: 100% !important;
  border-radius: unset;
}
</style>