<template>
    <v-card class="d-flex">
        <v-list min-width="120px">
            <v-list-item-group
                v-model="selectedTab"
                color="primary"
            >
                <v-list-item>                
                    <v-list-item-content>            
                        <v-list-item-title                 
                            v-text="'เงินสด'"
                        ></v-list-item-title>                  
                    </v-list-item-content>    
                </v-list-item>
                <v-list-item
                    v-for="(payment, index) in payments"
                    :key="payment.id"
                >                
                    <v-list-item-content>            
                        <v-list-item-title                 
                            v-text="payment.name"
                        ></v-list-item-title>                  
                    </v-list-item-content>    
                                    
                </v-list-item>              
            </v-list-item-group>
        </v-list>

        <v-divider vertical></v-divider>

        <div class="pa-2" style="width: 100%;">
            <div>
                <div>ยอดรวม : {{ $attrs.value.totalPrice | formatNumber }}</div>
                <div class="text-center text-h4 pa-4">
                    ฿ {{ !!pay ? pay : "0" }}
                </div>
                <div class="text-right">ทอน : {{ !!pay ? pay - $attrs.value.totalPrice : 0  }}</div>
                <div>
                    <NumPad
                    @numClick="numClickEvent"
                    @clear="pay = ''"
                    @delete="deleteEvent"
                    ></NumPad>
                </div>                
            </div>

            <v-divider></v-divider>

            <div>
                <v-container>
                    <v-row>
                        <v-col>
                            <v-textarea
                            outlined
                            v-model="$attrs.value.note"
                            label="หมายเหตุ"                            
                            rows="1"
                            dense
                            hide-details                    
                            @blur="setKeydownEvent"             
                            @focus="removeKeydownEvent"                                    
                            ></v-textarea>
                        </v-col>
                    </v-row>
                    <v-row no-gutters>
                        <v-col>
                            <v-checkbox
                            v-model="isNotPrint"
                            label="ไม่พิมพ์ใบเสร็จ"
                            hide-details
                            ></v-checkbox>
                        </v-col>
                    </v-row>                
                    <v-row no-gutters>
                        <v-col>
                            <v-checkbox
                            v-model="isMember"
                            label="เป็นสมาชิก"
                            hide-details
                            ></v-checkbox>
                        </v-col>
                    </v-row>                
                </v-container>
            </div>
            <div v-if="isMember">
                <v-container>
                    <v-row>
                        <v-col>
                            <AooVue2Combobox
                            outlined
                            hide-selected
                            hide-details="auto"
                            v-model="tel"
                            label="เบอร์ *"                    
                            :url="'/pos/customerListByTel'"
                            dense
                            ref="customerTelCombobox"                    
                            item-text="tel"
                            item-value="tel"               
                            @select="selectCustomer"                
                            @blur="setKeydownEvent"             
                            @focus="removeKeydownEvent"
                            :lazySearch="true"
                            ></AooVue2Combobox>
                            <!--
                            <delay-combobox
                            outlined
                            hide-selected
                            hide-details="auto"
                            v-model="tel"
                            label="เบอร์ *"                    
                            no-data-text="ไม่พบข้อมูล"
                            :url="'/pos/customerListByTel'"
                            dense
                            ref="customerTelCombobox"                    
                            item-text="tel"
                            item-value="tel"
                            :filter="filterCustomerByTel"                            
                            @change="selectCustomer"                
                            @blur="setKeydownEvent"             
                            @focus="removeKeydownEvent"
                            :lazySearch="true"
                            >
                            </delay-combobox>
                            -->
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <AooVue2Combobox
                            outlined
                            hide-selected
                            hide-details="auto"
                            v-model="name"
                            label="ชื่อ - นามสกุล"                    
                            no-data-text="ไม่พบข้อมูล"
                            :url="'/pos/customerListByName'"
                            dense
                            ref="customerNameCombobox"                    
                            item-text="name"
                            item-value="name"                            
                            @select="selectCustomer"   
                            @blur="setKeydownEvent"             
                            @focus="removeKeydownEvent"                            
                            ></AooVue2Combobox>
                            <!--
                            <delay-combobox
                            outlined
                            hide-selected
                            hide-details="auto"
                            v-model="name"
                            label="ชื่อ - นามสกุล"                    
                            no-data-text="ไม่พบข้อมูล"
                            :url="'/pos/customerListByName'"
                            dense
                            ref="customerNameCombobox"                    
                            item-text="name"
                            item-value="name"
                            :filter="filterCustomerByName"                            
                            @change="selectCustomer"   
                            @blur="setKeydownEvent"             
                            @focus="removeKeydownEvent"
                            :lazySearch="true"
                            >
                            </delay-combobox>
                            -->
                        </v-col>
                    </v-row>
                </v-container>                                
            </div>
            <div class="d-flex">
                <v-btn
                color="secondary"               
                outlined
                @click="close"
                :disabled="isSubmitData"
                >ยกเลิก</v-btn>
                <v-btn
                class="ml-auto"
                color="primary"
                @click="submit"
                :disabled="disableSubmit"
                :loading="isSubmitData"
                >ชำระเงิน</v-btn>
            </div>
        </div>
    
    </v-card>
</template>
<script>
import { POSOrder } from '@/classes/pos/POSOrder'
import AooVue2Combobox from '@/components/AooVue2Combobox.vue'
import { asyncPostAsJson } from '@/helpers/asyncAxios'
import errorParser from '@/plugins/errorparser'
import { isString } from '@vueuse/core'
import NumPad from './NumPad.vue'
export default {
  props: {
    payments: {
      type: Array,
      require: true,
    },
    deviceId: {
      type: [Number, String],
      require: true,
    },
  },
  data() {
    return {
      selectedTab: 0,
      pay: '',
      isNotPrint: false,
      isMember: false,
      tel: '',
      name: '',
      note: '',
      isSubmitData: false,
    }
  },
  watch: {
    '$attrs.value': function (value) {
      if (value) this.pay = ''
    },
    selectedTab(tabIndex) {
      if (!tabIndex) {
        this.selectedTab = 0
        this.$attrs.value.payby = null

        return
      }

      const payment = this.payments[tabIndex - 1]

      this.$attrs.value.payby = payment
    },
  },
  computed: {
    disableSubmit() {
      if (this.isMember && (!this.tel || !this.name)) return true

      if (!this.pay || Number.parseFloat(this.pay) < this.$attrs.value.totalPrice) return true

      return false
    },
  },
  mounted() {
    this.setKeydownEvent()
  },
  methods: {
    setKeydownEvent() {
      window.onkeydown = this.keydown
    },
    removeKeydownEvent() {
      window.onkeydown = null
    },
    keydown(event) {
      if (event.key == 'Backspace') this.deleteEvent()
      else if (event.key == '1') this.numClickEvent('1')
      else if (event.key == '2') this.numClickEvent('2')
      else if (event.key == '3') this.numClickEvent('3')
      else if (event.key == '4') this.numClickEvent('4')
      else if (event.key == '5') this.numClickEvent('5')
      else if (event.key == '6') this.numClickEvent('6')
      else if (event.key == '7') this.numClickEvent('7')
      else if (event.key == '8') this.numClickEvent('8')
      else if (event.key == '9') this.numClickEvent('9')
      else if (event.key == '0') this.numClickEvent('0')
      else if (event.key == '.') this.numClickEvent('.')
    },
    numClickEvent(event) {
      if (event[0] == '0' && !this.pay) return

      if (event == '.' && this.pay.search(/\./) >= 0) return

      if (event == '.' && !this.pay) this.pay = '0'

      this.pay += event
    },
    deleteEvent() {
      if (!this.pay) return

      this.pay = this.pay.slice(0, this.pay.length - 1)
    },
    filterCustomerByName(item, queryText, itemText) {
      return (
        itemText.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1 ||
        (item.name && item.name.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1)
      )
    },
    filterCustomerByTel(item, queryText, itemText) {
      return (
        itemText.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1 ||
        (item.tel && item.tel.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1)
      )
    },
    selectCustomer(payload) {
      if (!payload || isString(payload)) return

      const { name, tel } = payload

      if (!name || !tel) return

      this.tel = tel
      this.name = name
    },
    async submit() {
      this.isSubmitData = true
      try {
        const submitData = {
          ...this.$attrs.value.serialize(),
          tel: this.tel,
          name: this.name,
        }
        const { order, company } = await asyncPostAsJson('/pos/' + this.deviceId, submitData)

        if (!this.isNotPrint) {
          const responseOrder = new POSOrder(order)
          this.$store.commit('app/SET_PRINT_DATA', {
            type: this.$PRINT_TYPE.ABB,
            data: [responseOrder],
            company: {
              name: company.name,
              address: order.device.address,
              city: order.device.city,
              county: order.device.county,
              tel: order.device.tel,
              branch: order.device.branch,
              taxid: order.device.taxid,
            },
          })
        }

        this.$emit('done', this.pay - this.$attrs.value.totalPrice)
        this.reset()
      } catch (error) {
        this.$root.showCommonDialog('มีปัญหา', errorParser(error))
      }
      this.isSubmitData = false
    },
    close() {
      this.reset()
      this.$emit('close')
    },
    reset() {
      this.selectedTab = 0
      this.pay = ''
      this.isNotPrint = false
      this.isMember = false
      this.tel = ''
      this.name = ''
      this.note = ''
    },
  },
  components: {
    NumPad,
    AooVue2Combobox,
  },
}
</script>